




























import { useContext, useRouter } from '@nuxtjs/composition-api';

export default {
  name: 'InfoTile',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const pushToUrl = () => {
      const isStoryLink = props.blok.link.linktype === 'story';
      const url = props.blok?.link.cached_url as string;
      if (!isStoryLink) {
        window.open(url, '_blank');
      } else {
        router.push(`/${decodeURIComponent(url)}`);
      }
    };
    return {
      pushToUrl,
    };
  },
};
